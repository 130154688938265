<script setup>
import {useLocalePath} from 'vue-i18n-routing'

const {$event} = useNuxtApp()

const localePath = useLocalePath()

const props = defineProps({
  content: {
    type: Object,
    required: true,
  },
})

const content = reactive(props.content)

const openProperModal = (signal) => {
  // emit signal to open proper modal on shared bus
  $event(signal)
}

const goToPage = (url) => {
  return navigateTo(localePath(url))
}
</script>

<template>
  <section class="footer-ctas">
    <div class="footer-ctas-wrapper">
      <template v-for="cta in content.section" :key="cta.label">
        <div
          :class="[
            'footer-cta-block',
            'sensible',
            'animate-in-fade',
            'animate-out-fade',
            'is-link',
          ]"
          v-if="cta.action === 'link'"
          @click="goToPage(cta.url)">
          <span>{{ cta.label }}</span>
          <div class="footer-cta-btn btn btn--round btn--round-dark">
            <svg class="icon icon--btn-round icon--rotate-315">
              <use xlink:href="#icon-arrow"></use>
            </svg>
          </div>
        </div>
        <NuxtLink
          :class="[
            'footer-cta-block',
            'sensible',
            'animate-in-fade',
            'animate-out-fade',
            'is-link',
          ]"
          v-if="cta.action === 'external-link'"
          :to="cta.url" target="_blank">
          <span>{{ cta.label }}</span>
          <div class="footer-cta-btn btn btn--round btn--round-dark">
            <svg class="icon icon--btn-round icon--rotate-315">
              <use xlink:href="#icon-arrow"></use>
            </svg>
          </div>
        </NuxtLink>
      </template>
      <template v-for="cta in content.section" :key="cta.label">
        <div
          :class="[
            'footer-cta-block',
            'sensible',
            'animate-in-fade',
            'animate-out-fade',
          ]"
          v-if="cta.action != 'link' && cta.action != 'external-link'"
          @click="openProperModal(cta.action)">
          <span>{{ cta.label }}</span>
          <div class="footer-cta-btn btn btn--round btn--round-dark">
            <svg class="icon icon--btn-round icon--rotate-315">
              <use xlink:href="#icon-arrow"></use>
            </svg>
          </div>
        </div>
      </template>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.footer-ctas {
  // margin-top: pxrem(50);

  .footer-ctas-wrapper {
    @include mq(md) {
      display: flex;
    }
  }

  .footer-cta-block {
    @include make-col-ready();
    align-items: center;
    background-color: $color-white;
    border-right: 1px solid $color-gray-blue;
    border-top: 1px solid $color-gray-blue;
    display: block;
    padding-bottom: pxrem(80);
    padding-right: pxrem(70);
    padding-top: pxrem(15);
    position: relative;
    text-decoration: none;

    @include mq(md) {
      display: flex;
      flex: 1;
      height: pxrem(400);
      justify-content: center;
      padding: 0;
      text-align: center;
    }

    a {
      text-decoration: none;
    }

    &:hover {
      background-color: $color-gray-light;

      .footer-cta-btn {
        opacity: 1;
      }
    }

    span {
      @include display-2;
      max-width: 50%;
    }

    .footer-cta-btn {
      position: absolute;
      right: pxrem(15);
      transition: all $transition-default;
      top: pxrem(15);

      @include mq(md) {
        opacity: 0;
        opacity: 0;
      }
    }
  }
}
</style>
